// @ts-ignore
/* eslint-disable */
import request from "@/request";

/** deleteReport POST /api/report/delete */
export async function deleteReportUsingPost(
  body: API.DeleteRequest,
  options?: { [key: string]: any }
) {
  return request<API.BaseResponseBoolean_>("/api/report/delete", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    data: body,
    ...(options || {}),
  });
}

/** generateReport POST /api/report/generate */
export async function generateReportUsingPost(
  body: API.ReportGenerateRequest,
  options?: { [key: string]: any }
) {
  return request<API.BaseResponseReportRecord_>("/api/report/generate", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    data: body,
    ...(options || {}),
  });
}

/** getReportById GET /api/report/get */
export async function getReportByIdUsingGet(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.getReportByIdUsingGETParams,
  options?: { [key: string]: any }
) {
  return request<API.BaseResponseReportRecord_>("/api/report/get", {
    method: "GET",
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** listReportByPage POST /api/report/list/page */
export async function listReportByPageUsingPost(
  body: API.ReportRecordQueryRequest,
  options?: { [key: string]: any }
) {
  return request<API.BaseResponsePageReportRecordVO_>("/api/report/list/page", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    data: body,
    ...(options || {}),
  });
}

/** getAvailableTemplates GET /api/report/template/available */
export async function getAvailableTemplatesUsingGet(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.getAvailableTemplatesUsingGETParams,
  options?: { [key: string]: any }
) {
  return request<API.BaseResponseListReportTemplate_>(
    "/api/report/template/available",
    {
      method: "GET",
      params: {
        ...params,
      },
      ...(options || {}),
    }
  );
}

/** createReportTemplate POST /api/report/template/create */
export async function createReportTemplateUsingPost(
  body: API.ReportTemplate,
  options?: { [key: string]: any }
) {
  return request<API.BaseResponseReportTemplate_>(
    "/api/report/template/create",
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      data: body,
      ...(options || {}),
    }
  );
}

/** getDefaultTemplate GET /api/report/template/default */
export async function getDefaultTemplateUsingGet(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.getDefaultTemplateUsingGETParams,
  options?: { [key: string]: any }
) {
  return request<API.BaseResponseReportTemplate_>(
    "/api/report/template/default",
    {
      method: "GET",
      params: {
        ...params,
      },
      ...(options || {}),
    }
  );
}

/** listReportTemplateByPage POST /api/report/template/list/page */
export async function listReportTemplateByPageUsingPost(
  body: API.ReportTemplateQueryRequest,
  options?: { [key: string]: any }
) {
  return request<API.BaseResponsePageReportTemplate_>(
    "/api/report/template/list/page",
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      data: body,
      ...(options || {}),
    }
  );
}
