<template>
  <!-- 保持原有模板结构不变，仅调整class名称用于样式 -->
  <div id="chatRoomPage">
    <div class="chat-room-container">
      <!-- 用户列表部分 -->
      <div class="user-list">
        <h3>在线用户</h3>
        <ul>
          <li
            v-for="user in userList"
            :key="user.id"
            @click="selectUser(user)"
            :class="{ active: selectedUser?.id === user.id }"
          >
            <a-avatar :size="40" :image-url="user.userAvatar" />
            <span class="username">{{ user.userAccount }}</span>
          </li>
        </ul>
      </div>

      <!-- 聊天界面 -->
      <div class="chat-interface" v-if="selectedUser">
        <div class="chat-header">
          <a-avatar :size="50" :image-url="selectedUser.userAvatar" />
          <div class="user-info">
            <span class="chat-username">{{ selectedUser.userAccount }}</span>
            <span class="online-status">在线</span>
          </div>
        </div>

        <div class="message-list" ref="messageList">
          <ul>
            <li v-for="msg in filteredMessages" :key="msg.id">
              <div
                :class="{
                  'message-container': true,
                  'message-right': msg.senderId === currentUser?.id,
                  'message-left': msg.senderId !== currentUser?.id,
                }"
              >
                <a-avatar :size="40" :image-url="getUserAvatar(msg.senderId)" />
                <div class="message-content">
                  <p class="username">{{ getUserName(msg.senderId) }}</p>
                  <p class="message-bubble">{{ msg.content }}</p>
                  <p class="message-time">
                    {{ dayjs(msg.createTime).format("HH:mm") }}
                  </p>
                </div>
              </div>
            </li>
          </ul>
        </div>

        <div class="message-input">
          <a-input
            v-model="privateMessage"
            placeholder="输入消息..."
            @pressEnter="sendPrivateMessage"
            allow-clear
          />
          <a-button
            type="primary"
            @click="sendPrivateMessage"
            :disabled="!privateMessage.trim()"
            class="send-btn"
          >
            发送
          </a-button>
        </div>
      </div>

      <!-- 未选择用户时的占位 -->
      <div class="chat-placeholder" v-else>
        <div class="placeholder-content">
          <icon-comments style="font-size: 48px; color: #ccc" />
          <p>选择聊天对象开始对话</p>
        </div>
      </div>
    </div>
    <ChatSphere />
  </div>
</template>

<script setup lang="ts">
import API from "@/api";
import { computed, nextTick, onMounted, onUnmounted, ref } from "vue";
import dayjs from "dayjs";
import {
  getUserVoByIdUsingGet,
  listUserByPageUsingPost,
} from "@/api/userController";
import { useLoginUserStore } from "@/store/userStore";
import message from "@arco-design/web-vue/es/message";
import {
  getChatHistoryUsingGet,
  sendChatMessageUsingPost,
} from "@/api/chatMessageController";
import myAxios from "@/request";
import ChatSphere from "@/components/ChatSphere.vue"; // 引入 Axios 实例

// 当前登录用户和用户列表
const loginUserStore = useLoginUserStore();
const currentUser = ref<API.UserVO | null>(null); // 当前登录用户
const userList = ref<API.UserVO[]>([]); // 用户列表
const selectedUser = ref<API.UserVO | null>(null); // 当前选中的聊天用户
const privateMessage = ref(""); // 输入的私信内容
const messages = ref<API.ChatMessage[]>([]); // 聊天记录
const messageListRef = ref<HTMLElement | null>(null); // 消息列表的引用
// 心跳间隔（毫秒）
const HEARTBEAT_INTERVAL = 30000; // 30秒
const HEARTBEAT_TIMEOUT = 10000; // 心跳超时时间，10秒
let heartbeatInterval: ReturnType<typeof setInterval> | null = null;
let heartbeatTimeout: ReturnType<typeof setTimeout> | null = null;

// WebSocket 连接
let websocket: WebSocket | null = null;

// 重连相关
let reconnectAttempts = 0;
const MAX_RECONNECT_ATTEMPTS = 5; // 最大重试次数
let reconnectTimeout: ReturnType<typeof setTimeout> | null = null;

// 加载用户列表
const loadUserList = async () => {
  try {
    const res = await listUserByPageUsingPost({});
    if (res.data.code === 0) {
      userList.value = res.data.data.records;
    } else {
      message.error("获取用户列表失败: " + res.data.message);
    }
  } catch (error) {
    message.error("加载用户列表时发生错误");
  }
};

// 生成聊天 ID
const generateChatId = (userId1: string, userId2: string): string => {
  const id1 = userId1 < userId2 ? userId1 : userId2;
  const id2 = userId1 < userId2 ? userId2 : userId1;
  return `${id1}_${id2}`; // 确保顺序
};

// 加载聊天记录
const loadChatHistory = async () => {
  if (!selectedUser.value || !currentUser.value) return;

  const chatId = generateChatId(currentUser.value.id, selectedUser.value.id); // 使用生成的 chatId
  try {
    const res = await getChatHistoryUsingGet({
      chatId: chatId,
      limit: "20",
    });

    if (res.data.code === 0) {
      messages.value = res.data.data.sort(
        (a, b) => dayjs(a.createTime).unix() - dayjs(b.createTime).unix()
      );
      console.log("Chat History:", messages.value);
      // 加载完消息后，滚动到最新消息
      await nextTick(() => {
        if (messageListRef.value) {
          messageListRef.value.scrollTop = messageListRef.value.scrollHeight;
        }
      });
    } else {
      message.error("获取聊天记录失败: " + res.data.message);
    }
  } catch (error) {
    message.error("加载聊天记录时发生错误");
  }
};

// 选择聊天用户
const selectUser = (user: API.UserVO) => {
  // 判断是否是自己
  if (user.id === currentUser.value?.id) {
    message.error("无法和自己聊天！");
    return;
  }
  selectedUser.value = user;
  loadChatHistory(); // 加载该用户的聊天记录
};

// 根据选中的用户过滤聊天记录
const filteredMessages = computed(() => {
  if (!selectedUser.value) return [];
  return messages.value.filter((msg) => {
    return (
      (msg.senderId === selectedUser.value.id &&
        msg.receiverId === currentUser.value?.id) ||
      (msg.senderId === currentUser.value?.id &&
        msg.receiverId === selectedUser.value.id)
    );
  });
});

// 获取用户头像
const getUserAvatar = (userId: string) => {
  const user = userList.value.find((user) => user.id === userId);
  return user ? user.userAvatar : ""; // 返回头像 URL 或空字符串
};

// 获取用户名
const getUserName = (userId: string) => {
  const user = userList.value.find((user) => user.id === userId);
  return user ? user.userAccount : "未知用户"; // 返回用户名或"未知用户"
};

// 发送私信
const sendPrivateMessage = async () => {
  if (
    !privateMessage.value.trim() ||
    !currentUser.value ||
    !selectedUser.value
  ) {
    message.error("无法发送空消息或未选择用户");
    return;
  }

  const chatId = generateChatId(currentUser.value.id, selectedUser.value.id); // 使用生成的 chatId
  const newMessage: API.ChatMessage = {
    id: null, // 如果您有消息 ID 的生成逻辑，可以在这里替换
    chatId: chatId,
    senderId: currentUser.value.id,
    receiverId: selectedUser.value.id,
    content: privateMessage.value,
    createTime: dayjs().format("YYYY-MM-DD HH:mm:ss"),
  };

  try {
    // 发送消息请求
    await sendChatMessageUsingPost(newMessage);
    messages.value.push(newMessage); // 立即添加消息到列表
    privateMessage.value = ""; // 清空输入框

    // 通过 WebSocket 发送消息
    if (websocket && websocket.readyState === WebSocket.OPEN) {
      websocket.send(JSON.stringify(newMessage));
    } else {
      message.error("WebSocket 未连接，无法发送消息");
    }

    // 刷新聊天记录
    await loadChatHistory();
  } catch (error) {
    console.error("发送消息时发生错误:", error);
    message.error("发送消息时发生错误");
  }
};

// 处理接收到的消息
const handleIncomingMessage = (event: MessageEvent) => {
  // 先检查是否是心跳消息
  if (event.data === "ping") {
    return; // 忽略心跳消息
  }

  try {
    const messageData: API.ChatMessage = JSON.parse(event.data);
    if (
      (messageData.senderId === selectedUser.value?.id &&
        messageData.receiverId === currentUser.value?.id) ||
      (messageData.senderId === currentUser.value?.id &&
        messageData.receiverId === selectedUser.value.id)
    ) {
      messages.value.push(messageData);
      loadChatHistory(); // 刷新聊天记录
    }
  } catch (error) {
    console.error("接收到的消息格式错误:", event.data); // 记录原始消息
  }
};

// 获取 Axios 实例的 baseURL
const baseURL = myAxios.defaults.baseURL;
console.log(baseURL);

// 启动 WebSocket 连接
const startWebSocket = () => {
  if (!currentUser.value) {
    console.error("当前用户尚未加载，无法启动 WebSocket 连接");
    return;
  }

  // 动态构建 WebSocket URL
  const websocketURL = `${myAxios.defaults.baseURL
    .replace(/^http/, "ws")
    .replace(/^https/, "wss")
    .replace(/\/$/, "")}/api/websocket/${currentUser.value.id}`;

  websocket = new WebSocket(websocketURL);

  websocket.onopen = () => {
    message.success("WebSocket 连接成功");
    reconnectAttempts = 0; // 重置重连计数器
    if (reconnectTimeout) clearTimeout(reconnectTimeout); // 清除重连定时器

    // 每 30 秒发送心跳包
    heartbeatInterval = setInterval(sendHeartbeat, HEARTBEAT_INTERVAL);
  };

  websocket.onmessage = (event) => {
    if (heartbeatTimeout) clearTimeout(heartbeatTimeout); // 收到消息后清除心跳超时检测
    handleIncomingMessage(event);
  };

  websocket.onerror = (event) => {
    console.error("WebSocket 连接出错:", event);
    message.error("WebSocket 连接出错，请检查连接状态");
  };

  websocket.onclose = () => {
    message.warning("WebSocket 连接已断开，正在尝试重连...");
    reconnectWebSocket();
  };
};

// 加载登录用户信息
const loadLoginUser = async () => {
  try {
    const res = await getUserVoByIdUsingGet({
      id: loginUserStore.loginUser?.id,
    });
    if (res.data.code === 0) {
      currentUser.value = res.data.data;
      startWebSocket(); // 用户加载后启动 WebSocket
      await loadUserList(); // 加载用户列表
    } else {
      message.error("加载用户信息失败: " + res.data.message);
    }
  } catch (error) {
    message.error("加载用户信息时发生错误");
  }
};

// 发送心跳包
const sendHeartbeat = () => {
  if (websocket && websocket.readyState === WebSocket.OPEN) {
    websocket.send("ping");
    heartbeatTimeout = setTimeout(() => {
      message.warning("WebSocket 心跳超时，正在尝试重连...");
      reconnectWebSocket();
    }, HEARTBEAT_TIMEOUT);
  } else {
    reconnectWebSocket();
  }
};

// 重连 WebSocket
const reconnectWebSocket = () => {
  if (reconnectAttempts < MAX_RECONNECT_ATTEMPTS) {
    reconnectAttempts++;
    reconnectTimeout = setTimeout(() => {
      startWebSocket();
    }, 2000); // 2秒后重连
  } else {
    message.error("已达到最大重连次数，请手动刷新页面");
  }
};

// 组件挂载时加载登录用户
onMounted(() => {
  loadLoginUser();
});

// 组件卸载时清理 WebSocket 连接
onUnmounted(() => {
  if (websocket) {
    websocket.close();
  }
  if (heartbeatInterval) {
    clearInterval(heartbeatInterval);
  }
});
</script>

<style scoped lang="scss">
#chatRoomPage {
  display: flex;
  height: 100vh;
  background: #f0f2f5;
}

.chat-room-container {
  display: flex;
  width: 100%;
  max-width: 1200px;
  height: calc(100vh - 80px);
  margin: 20px auto;
  overflow: hidden;
  background: #fff;
  border-radius: 12px;
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.1);
}

.user-list {
  width: 280px;
  background: #fafafa;
  border-right: 1px solid #e8e8e8;

  h3 {
    margin: 0;
    padding: 16px;
    color: #333;
    font-size: 16px;
    border-bottom: 1px solid #e8e8e8;
  }

  ul {
    height: calc(100vh - 160px);
    padding: 8px 0;
    overflow-y: auto;
  }

  li {
    display: flex;
    align-items: center;
    padding: 12px 16px;
    cursor: pointer;
    transition: background 0.3s;

    &:hover {
      background: #f0f0f0;
    }

    &.active {
      background: #e6f4ff;
    }

    .username {
      margin-left: 12px;
      color: #333;
    }
  }
}

.chat-interface {
  display: flex;
  flex: 1;
  flex-direction: column;
}

.chat-header {
  display: flex;
  align-items: center;
  padding: 16px;
  border-bottom: 1px solid #e8e8e8;

  .user-info {
    margin-left: 12px;

    .chat-username {
      color: #333;
      font-weight: 500;
    }

    .online-status {
      color: #52c41a;
      font-size: 12px;
    }
  }
}

.message-list {
  flex: 1;
  padding: 20px;
  overflow-y: auto;

  ul {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  .message-container {
    display: flex;
    margin-bottom: 20px;

    &.message-right {
      flex-direction: row-reverse;

      .message-content {
        align-items: flex-end;
        color: white;
        background: #1890ff;

        .message-time {
          color: rgba(255, 255, 255, 0.8);
        }
      }
    }

    &.message-left {
      .message-content {
        color: #333;
        background: #f5f5f5;
      }
    }
  }

  .message-content {
    position: relative;
    max-width: 70%;
    margin: 0 12px;
    padding: 12px 16px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }

  .username {
    margin-bottom: 4px;
    color: #666;
    font-size: 12px;
  }

  .message-time {
    margin-top: 4px;
    color: #999;
    font-size: 12px;
    text-align: right;
  }
}

.message-input {
  display: flex;
  gap: 12px;
  padding: 16px;
  border-top: 1px solid #e8e8e8;

  :deep(.arco-input) {
    padding: 8px 16px;
    border-radius: 20px;
  }

  .send-btn {
    padding: 0 24px;
    border-radius: 20px;
  }
}

.chat-placeholder {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  background: #fafafa;

  .placeholder-content {
    text-align: center;

    p {
      margin-top: 16px;
      color: #999;
    }
  }
}
</style>
