<template>
  <a-form
    :model="formSearchParams"
    :style="{ marginBottom: '20px' }"
    layout="inline"
    @submit="doSearch"
  >
    <a-form-item field="reportName" label="报告标题">
      <a-input
        v-model="formSearchParams.reportName"
        placeholder="请输入报告标题"
        allow-clear
      />
    </a-form-item>
    <a-form-item field="appId" label="应用 ID">
      <a-input
        v-model="formSearchParams.appId"
        placeholder="请输入应用 ID"
        allow-clear
      />
    </a-form-item>
    <a-form-item field="userId" label="用户 ID">
      <a-input
        v-model="formSearchParams.userId"
        placeholder="请输入用户 ID"
        allow-clear
      />
    </a-form-item>
    <a-form-item field="templateId" label="模板 ID">
      <a-input
        v-model="formSearchParams.templateId"
        placeholder="请输入模板 ID"
        allow-clear
      />
    </a-form-item>
    <a-form-item>
      <a-button type="primary" html-type="submit" style="width: 100px">
        搜索
      </a-button>
    </a-form-item>
  </a-form>
  <a-table
    :columns="columns"
    :data="dataList"
    :pagination="{
      showTotal: true,
      pageSize: searchParams.pageSize,
      current: searchParams.current,
      total,
    }"
    @page-change="onPageChange"
  >
    <template #coverImage="{ record }">
      <a-image width="64" :src="getReportCoverImage(record)" />
    </template>
    <template #createTime="{ record }">
      {{ dayjs(record.createTime).format("YYYY-MM-DD HH:mm:ss") }}
    </template>
    <template #updateTime="{ record }">
      {{ dayjs(record.updateTime).format("YYYY-MM-DD HH:mm:ss") }}
    </template>
    <template #optional="{ record }">
      <a-space>
        <a-button type="primary" @click="viewReport(record)">查看</a-button>
        <a-button status="danger" @click="doDelete(record)">删除</a-button>
      </a-space>
    </template>
  </a-table>
</template>

<script setup lang="ts">
import { ref, watchEffect } from "vue";
import {
  deleteReportUsingPost,
  listReportByPageUsingPost,
} from "@/api/reportController";
import API from "@/api";
import message from "@arco-design/web-vue/es/message";
import { dayjs } from "@arco-design/web-vue/es/_utils/date";
import { useRouter } from "vue-router";

const router = useRouter();
const formSearchParams = ref<API.ReportRecordQueryRequest>({});

// 初始化搜索条件（不应该被修改）
const initSearchParams = {
  current: 1,
  pageSize: 10,
};

const searchParams = ref<API.ReportRecordQueryRequest>({
  ...initSearchParams,
});
const dataList = ref<API.ReportRecordVO[]>([]);
const total = ref<number>(0);

// 默认封面图片地址
const DEFAULT_COVER_IMAGE =
  "https://placeholder.pics/svg/300x200/DEDEDE/555555/Report%20Cover";

/**
 * 获取报告封面图片，如果不存在则使用默认图片
 * @param record 报告记录
 * @returns 封面图片URL
 */
const getReportCoverImage = (record: API.ReportRecordVO) => {
  return record.coverImage || DEFAULT_COVER_IMAGE;
};

/**
 * 加载数据
 */
const loadData = async () => {
  const res = await listReportByPageUsingPost(searchParams.value);
  if (res.data.code === 0) {
    dataList.value = res.data.data?.records || [];
    total.value = res.data.data?.total || 0;
  } else {
    message.error("获取数据失败，" + res.data.message);
  }
};

/**
 * 执行搜索
 */
const doSearch = () => {
  searchParams.value = {
    ...initSearchParams,
    ...formSearchParams.value,
  };
};

/**
 * 当分页变化时，改变搜索条件，触发数据加载
 * @param page
 */
const onPageChange = (page: number) => {
  searchParams.value = {
    ...searchParams.value,
    current: page,
  };
};

/**
 * 查看报告详情
 * @param record
 */
const viewReport = (record: API.ReportRecordVO) => {
  if (!record.id) {
    return;
  }
  router.push({
    path: "/report/detail",
    query: { id: record.id },
  });
};

/**
 * 删除报告
 * @param record
 */
const doDelete = async (record: API.ReportRecordVO) => {
  if (!record.id) {
    return;
  }

  const res = await deleteReportUsingPost({
    id: record.id,
  });
  if (res.data.code === 0) {
    message.success("删除成功");
    loadData();
  } else {
    message.error("删除失败，" + res.data.message);
  }
};

/**
 * 监听 searchParams 变量，改变时触发数据的重新加载
 */
watchEffect(() => {
  loadData();
});

// 表格列配置
const columns = [
  {
    title: "ID",
    dataIndex: "id",
  },
  {
    title: "报告标题",
    dataIndex: "reportName", // 修改为 reportName 以匹配 API 返回数据
  },
  {
    title: "封面图片",
    dataIndex: "coverImage",
    slotName: "coverImage",
  },
  {
    title: "应用 ID",
    dataIndex: "appId",
  },
  {
    title: "应用名称",
    dataIndex: "appName", // 添加应用名称列
  },
  {
    title: "用户 ID",
    dataIndex: "userId",
  },
  {
    title: "用户名称",
    dataIndex: "user.userName", // 添加用户名称列
    render: ({ record }) => record.user?.userName || "-",
  },
  {
    title: "模板 ID",
    dataIndex: "templateId",
  },
  {
    title: "报告类型",
    dataIndex: "reportType",
  },
  {
    title: "创建时间",
    dataIndex: "createTime",
    slotName: "createTime",
  },
  {
    title: "更新时间",
    dataIndex: "updateTime",
    slotName: "updateTime",
  },
  {
    title: "操作",
    slotName: "optional",
  },
];
</script>
