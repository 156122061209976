<template>
  <div class="chat-container">
    <!-- 头部区域 -->
    <div class="header">
      <div class="app-title">
        <span class="logo">🤖</span>
        <h2>机器人</h2>
      </div>
      <div class="user-profile">
        <div class="avatar">ME</div>
        <div class="user-info">
          <div class="username">{{ user.username }}</div>
          <div class="status">在线</div>
        </div>
      </div>
    </div>

    <!-- 消息区域 -->
    <div class="message-area">
      <div v-if="messages.length === 0" class="empty-state">
        <div class="empty-icon">💬</div>
        <div class="empty-text">暂无聊天记录</div>
      </div>

      <div v-else class="messages">
        <div
          v-for="msg in messages"
          :key="msg.id"
          :class="['message', msg.type]"
        >
          <div v-if="msg.type === 'ai'" class="ai-avatar">bot</div>
          <div class="bubble">{{ msg.content }}</div>
          <div v-if="msg.type === 'user'" class="user-avatar">ME</div>
        </div>
      </div>
    </div>

    <!-- 输入区域 -->
    <div class="input-container">
      <div class="input-wrapper">
        <textarea
          v-model="userInput"
          placeholder="输入消息..."
          rows="1"
          class="message-input"
          @keydown.enter.prevent="sendMessage"
        ></textarea>
        <button class="send-button" @click="sendMessage">
          <svg class="send-icon" viewBox="0 0 24 24">
            <path d="M2.01 21L23 12 2.01 3 2 10l15 2-15 2z" />
          </svg>
        </button>
      </div>
      <div class="session-info">会话ID: {{ sessionId }}</div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, onBeforeUnmount, onMounted, ref } from "vue";
import {
  chatCompletionsUsingGet,
  createChatGlmRecordUsingPost,
  getChatGlmRecordUsingGet,
} from "@/api/chatGlmController";

interface Message {
  id: number;
  content: string;
  type: "user" | "ai";
}

export default defineComponent({
  components: {
    // AAvatar: Avatar,
    // ACard: Card,
    // AList: List,
    // AInput: Input,
    // AButton: Button,
  },
  setup() {
    const userInput = ref<string>(""); // 用户输入
    const messages = ref<Message[]>([]); // 消息列表
    const user = ref({
      avatar: "user-avatar.png", // 用户头像 URL
      username: "User123", // 用户昵称
      id: "12345", // 用户 ID
    });
    const sessionId = ref<string>(
      `session-${Math.floor(Math.random() * 100000)}`
    ); // 会话 ID

    // 获取聊天记录
    const fetchChatRecords = async () => {
      try {
        const response = await getChatGlmRecordUsingGet();
        console.log("Fetched chat records:", response);
        if (response && Array.isArray(response.data)) {
          messages.value = response.data; // 确保返回的数据格式正确
        } else {
          console.error("Unexpected data format:", response.data);
        }
      } catch (error) {
        console.error("Error fetching chat records:", error);
      }
    };

    // 发送消息
    const sendMessage = async () => {
      if (userInput.value.trim()) {
        const userMessage: Message = {
          id: messages.value.length + 1,
          content: userInput.value,
          type: "user",
        };

        messages.value.push(userMessage); // 推送用户消息
        await createChatRecord(userMessage.content);
        userInput.value = ""; // 清空输入框
        await startAIResponse(userMessage.content); // 开始获取 AI 响应
      }
    };

    // 创建聊天记录
    const createChatRecord = async (message: string) => {
      try {
        await createChatGlmRecordUsingPost({
          userId: user.value.id,
          message,
          sessionId: sessionId.value,
        });
      } catch (error) {
        console.error("Error creating chat record:", error);
      }
    };

    // 开始获取 AI 响应
    // 开始获取 AI 响应
    const startAIResponse = async (message: string) => {
      try {
        const response = await chatCompletionsUsingGet({
          userId: user.value.id,
          message,
          model: undefined, // 传入模型类型，如果有需要可以设置默认值
          compatible: false, // 默认兼容性
        });

        // 打印返回的日志
        console.log("AI Response:", response);

        // 确保 response.data 存在并且不是空字符串
        if (response && response.data && typeof response.data === "string") {
          const aiMessage: Message = {
            id: messages.value.length + 1,
            content: response.data, // 直接使用 response.data
            type: "ai",
          };
          messages.value.push(aiMessage); // 推送 AI 消息
        } else {
          console.error("Invalid response structure:", response);
        }
      } catch (error) {
        console.error("Error fetching AI response:", error);
      }
    };

    // 组件挂载时获取聊天记录
    onMounted(() => {
      fetchChatRecords();
    });

    // 组件卸载时清理资源
    onBeforeUnmount(() => {
      // 清理代码逻辑
    });

    return {
      userInput,
      messages,
      user,
      sessionId,
      sendMessage,
    };
  },
});
</script>

<style scoped>
.chat-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  background: #f5f7fb;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 24px;
  background: #fff;
  border-bottom: 1px solid #e8e8e8;
}

.app-title {
  display: flex;
  gap: 12px;
  align-items: center;
}

.logo {
  font-size: 28px;
}

.user-profile {
  display: flex;
  gap: 12px;
  align-items: center;
}

.avatar {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  color: white;
  font-weight: 500;
  background: #2196f3;
  border-radius: 50%;
}

.user-info {
  display: flex;
  flex-direction: column;
}

.username {
  font-weight: 500;
}

.status {
  color: #666;
  font-size: 12px;
}

.message-area {
  flex: 1;
  padding: 20px;
  overflow-y: auto;
  background: #fafafa;
}

.empty-state {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  color: #999;
}

.empty-icon {
  margin-bottom: 16px;
  font-size: 48px;
}

.messages {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.message {
  display: flex;
  gap: 12px;
  max-width: 80%;
}

.message.user {
  flex-direction: row-reverse;
  align-self: flex-end;
}

.bubble {
  padding: 12px 16px;
  line-height: 1.5;
  background: #fff;
  border-radius: 18px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.05);
}

.message.ai .bubble {
  background: #e3f2fd;
  border-radius: 18px 18px 18px 4px;
}

.message.user .bubble {
  color: white;
  background: #2196f3;
  border-radius: 18px 18px 4px 18px;
}

.ai-avatar,
.user-avatar {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  color: white;
  background: #4caf50;
  border-radius: 50%;
}

.user-avatar {
  background: #2196f3;
}

.input-container {
  padding: 16px;
  background: #fff;
  border-top: 1px solid #e8e8e8;
}

.input-wrapper {
  display: flex;
  gap: 12px;
  margin-bottom: 8px;
}

.message-input {
  flex: 1;
  padding: 12px 16px;
  line-height: 1.5;
  border: 1px solid #e0e0e0;
  border-radius: 24px;
  transition: all 0.2s;
  resize: none;
}

.message-input:focus {
  border-color: #2196f3;
  outline: none;
  box-shadow: 0 0 0 2px rgba(33, 150, 243, 0.2);
}

.send-button {
  width: 48px;
  height: 48px;
  color: white;
  background: #2196f3;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  transition: all 0.2s;
}

.send-button:hover {
  background: #1976d2;
  transform: scale(1.05);
}

.send-icon {
  width: 24px;
  height: 24px;
}

.session-info {
  color: #666;
  font-size: 12px;
  text-align: right;
}
</style>
