import { createApp } from "vue";
import ArcoVue from "@arco-design/web-vue";
import ArcoVueIcon from "@arco-design/web-vue/es/icon";
import "@arco-design/web-vue/dist/arco.css";
import { createPinia } from "pinia";
import App from "./App.vue";
import router from "./router";
import "../src/access";
import VueGtag from "vue-gtag";
// 引入 Vercel Analytics
import { inject } from "@vercel/analytics";

// 初始化 Vercel Analytics (一行代码即可)
inject();

// 先创建应用实例
const app = createApp(App);

app.use(VueGtag, {
  config: {
    id: "G-J85XGRQJ3H",
    params: {
      send_page_view: true, // 确保发送页面浏览事件
    },
  },
  appName: "xudada-x", // 添加应用名称
  enabled: true, // 确保启用
  pageTrackerScreenviewEnabled: true, // 启用屏幕视图跟踪
});

const pinia = createPinia();

app.use(ArcoVue);
app.use(ArcoVueIcon);
app.use(router);
app.use(pinia);
app.mount("#app");

const debounce = ({
  fn,
  delay,
}: {
  fn: (...args: any[]) => void;
  delay: number;
}) => {
  let timer: ReturnType<typeof setTimeout>;
  return (...args: any[]) => {
    if (timer) {
      clearTimeout(timer);
    }
    timer = setTimeout(() => {
      fn(...args);
    }, delay);
  };
};

const _ResizeObserver = window.ResizeObserver;
window.ResizeObserver = class ResizeObserver extends _ResizeObserver {
  constructor(callback: any) {
    callback = debounce({ fn: callback, delay: 200 });
    super(callback);
  }
};
