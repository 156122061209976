<template>
  <a-form
    :model="formSearchParams"
    :style="{ marginBottom: '20px' }"
    layout="inline"
    @submit="doSearch"
  >
    <a-form-item field="resultName" label="结果名称">
      <a-input
        v-model="formSearchParams.resultName"
        placeholder="请输入结果名称"
        allow-clear
      />
    </a-form-item>
    <a-form-item field="resultDesc" label="结果描述">
      <a-input
        v-model="formSearchParams.resultDesc"
        placeholder="请输入结果描述"
        allow-clear
      />
    </a-form-item>
    <a-form-item field="appId" label="应用 id">
      <a-input
        v-model="formSearchParams.appId"
        placeholder="请输入应用 id"
        allow-clear
      />
    </a-form-item>
    <a-form-item field="userId" label="用户 id">
      <a-input
        v-model="formSearchParams.userId"
        placeholder="请输入用户 id"
        allow-clear
      />
    </a-form-item>
    <a-form-item>
      <a-button type="primary" html-type="submit" style="width: 100px">
        搜索
      </a-button>
    </a-form-item>
    <a-form-item>
      <a-dropdown>
        <a-button type="outline" style="margin-left: 10px"> 生成报告</a-button>
        <template #content>
          <a-doption @click="generateCurrentPageReport"
            >生成当前页报告
          </a-doption>
          <a-doption @click="generateAllDataReport">生成全量数据报告</a-doption>
          <a-doption @click="showTemplateSelect">使用模板生成报告</a-doption>
        </template>
      </a-dropdown>
    </a-form-item>
    <!-- 添加永久显示的查看最新报告按钮 -->
    <a-form-item v-if="lastReportId">
      <a-button
        type="primary"
        status="success"
        @click="viewLastReport"
        style="margin-left: 10px"
      >
        查看最新报告
      </a-button>
    </a-form-item>
  </a-form>

  <!-- 报告生成成功提示 -->
  <a-alert
    v-if="generatedReportId"
    type="success"
    closable
    @close="generatedReportId = ''"
  >
    <template #message>
      <div class="report-success-message">
        报告生成成功！
        <a-button
          type="text"
          class="view-report-btn"
          @click="viewReport(generatedReportId)"
        >
          查看报告
        </a-button>
      </div>
    </template>
  </a-alert>

  <a-table
    :columns="columns"
    :data="dataList"
    :pagination="{
      showTotal: true,
      pageSize: searchParams.pageSize,
      current: searchParams.current,
      total,
    }"
    @page-change="onPageChange"
  >
    <template #resultPicture="{ record }">
      <a-image width="64" :src="record.resultPicture" />
    </template>
    <template #createTime="{ record }">
      {{ dayjs(record.createTime).format("YYYY-MM-DD HH:mm:ss") }}
    </template>
    <template #updateTime="{ record }">
      {{ dayjs(record.updateTime).format("YYYY-MM-DD HH:mm:ss") }}
    </template>
    <template #optional="{ record }">
      <a-space>
        <a-button status="danger" @click="doDelete(record)">删除</a-button>
      </a-space>
    </template>
  </a-table>

  <!-- 模板选择对话框 -->
  <a-modal
    v-model:visible="templateSelectVisible"
    title="选择报告模板"
    @cancel="templateSelectVisible = false"
    @ok="generateReportWithTemplate"
    :ok-loading="generateLoading"
  >
    <a-form :model="reportForm" layout="vertical">
      <a-form-item field="reportName" label="报告名称">
        <a-input v-model="reportForm.reportName" placeholder="请输入报告名称" />
      </a-form-item>
      <a-form-item field="reportDesc" label="报告描述">
        <a-textarea
          v-model="reportForm.reportDesc"
          placeholder="请输入报告描述"
        />
      </a-form-item>
      <a-form-item field="templateId" label="报告模板">
        <a-select v-model="reportForm.templateId" placeholder="请选择报告模板">
          <a-option
            v-for="template in templates"
            :key="template.id"
            :value="template.id"
          >
            {{ template.templateName }} ({{ template.templateType }})
          </a-option>
        </a-select>
      </a-form-item>
      <a-form-item field="generateAll" label="数据范围">
        <a-radio-group v-model="reportForm.generateAll">
          <a-radio :value="false">当前页数据</a-radio>
          <a-radio :value="true">全部数据</a-radio>
        </a-radio-group>
      </a-form-item>
    </a-form>
  </a-modal>
</template>

<script setup lang="ts">
import { onMounted, ref, watchEffect } from "vue";
import {
  deleteScoringResultUsingPost,
  listScoringResultByPageUsingPost,
} from "@/api/scoringResultController";
import {
  generateReportUsingPost,
  getAvailableTemplatesUsingGet,
} from "@/api/reportController";
import API from "@/api";
import message from "@arco-design/web-vue/es/message";
import { dayjs } from "@arco-design/web-vue/es/_utils/date";

const formSearchParams = ref<API.ScoringResultQueryRequest>({});

// 初始化搜索条件（不应该被修改）
const initSearchParams = {
  current: 1,
  pageSize: 10,
};

const searchParams = ref<API.ScoringResultQueryRequest>({
  ...initSearchParams,
});
const dataList = ref<API.ScoringResult[]>([]);
const total = ref<number>(0);

// 模板选择相关
const templateSelectVisible = ref(false);
const templates = ref([]);
const generateLoading = ref(false);
const reportForm = ref({
  reportName: "",
  reportDesc: "",
  templateId: null,
  generateAll: false,
  reportType: "standard",
});

// 存储生成的报告ID
const generatedReportId = ref("");
// 存储最新报告ID，用于永久显示按钮
const lastReportId = ref("");

/**
 * 加载数据
 */
const loadData = async () => {
  try {
    const res = await listScoringResultByPageUsingPost(searchParams.value);
    if (res.data && res.data.code === 0) {
      dataList.value = res.data.data?.records || [];
      total.value = res.data.data?.total || 0;
    } else {
      message.error(
        "获取数据失败，" + (res.data ? res.data.message : "未知错误")
      );
    }
  } catch (error) {
    console.error("加载数据出错:", error);
    message.error("加载数据出错，请检查网络连接");
  }
};

/**
 * 执行搜索
 */
const doSearch = () => {
  searchParams.value = {
    ...initSearchParams,
    ...formSearchParams.value,
  };
};

/**
 * 当分页变化时，改变搜索条件，触发数据加载
 * @param page
 */
const onPageChange = (page: number) => {
  searchParams.value = {
    ...searchParams.value,
    current: page,
  };
};

/**
 * 删除
 * @param record
 */
const doDelete = async (record: API.ScoringResult) => {
  if (!record.id) {
    return;
  }

  try {
    const res = await deleteScoringResultUsingPost({
      id: record.id,
    });
    if (res.data && res.data.code === 0) {
      message.success("删除成功");
      loadData();
    } else {
      message.error("删除失败，" + (res.data ? res.data.message : "未知错误"));
    }
  } catch (error) {
    console.error("删除出错:", error);
    message.error("删除出错，请检查网络连接");
  }
};

/**
 * 查看报告
 * @param reportId 报告ID
 */
const viewReport = (reportId: string) => {
  if (reportId) {
    window.open(`/report/detail?id=${reportId}`, "_blank");
  }
};

/**
 * 查看最新报告
 */
const viewLastReport = () => {
  if (lastReportId.value) {
    viewReport(lastReportId.value);
  } else {
    message.warning("暂无可查看的报告");
  }
};

/**
 * 生成当前页报告
 */
const generateCurrentPageReport = async () => {
  const appId = formSearchParams.value.appId || searchParams.value.appId;
  if (!appId) {
    message.warning("请先选择应用ID");
    return;
  }

  const reportRequest = {
    appId: Number(appId),
    current: searchParams.value.current,
    pageSize: searchParams.value.pageSize,
    resultName: formSearchParams.value.resultName,
    resultDesc: formSearchParams.value.resultDesc,
    userId: formSearchParams.value.userId,
    reportName: `评分报告-${dayjs().format("YYYY-MM-DD")}`,
    reportDesc: `当前页评分数据报告，生成于${dayjs().format(
      "YYYY-MM-DD HH:mm:ss"
    )}`,
    generateAll: false,
    reportType: "standard",
  };

  try {
    const res = await generateReportUsingPost(reportRequest);
    if (res.data && res.data.code === 0) {
      message.success("报告生成成功");
      // 保存报告ID
      if (res.data.data?.id) {
        const reportId = String(res.data.data.id);
        console.log("生成的报告ID:", reportId);
        generatedReportId.value = reportId;
        lastReportId.value = reportId; // 同时更新最新报告ID
        // 保存到本地存储
        localStorage.setItem("lastReportId", reportId);
      }
    } else {
      message.error(
        "报告生成失败，" + (res.data ? res.data.message : "未知错误")
      );
    }
  } catch (error) {
    console.error("生成报告出错：", error);
    message.error("生成报告出错，请稍后重试");
  }
};

/**
 * 生成全量数据报告
 */
const generateAllDataReport = async () => {
  const appId = formSearchParams.value.appId || searchParams.value.appId;
  if (!appId) {
    message.warning("请先选择应用ID");
    return;
  }

  const reportRequest = {
    appId: Number(appId),
    resultName: formSearchParams.value.resultName,
    resultDesc: formSearchParams.value.resultDesc,
    userId: formSearchParams.value.userId,
    reportName: `评分报告-全量数据-${dayjs().format("YYYY-MM-DD")}`,
    reportDesc: `全量评分数据报告，生成于${dayjs().format(
      "YYYY-MM-DD HH:mm:ss"
    )}`,
    generateAll: true,
    reportType: "standard",
  };

  try {
    const res = await generateReportUsingPost(reportRequest);
    if (res.data && res.data.code === 0) {
      message.success("报告生成成功");
      // 保存报告ID
      if (res.data.data?.id) {
        const reportId = String(res.data.data.id);
        console.log("生成的报告ID:", reportId);
        generatedReportId.value = reportId;
        lastReportId.value = reportId; // 同时更新最新报告ID
        // 保存到本地存储
        localStorage.setItem("lastReportId", reportId);
      }
    } else {
      message.error(
        "报告生成失败，" + (res.data ? res.data.message : "未知错误")
      );
    }
  } catch (error) {
    console.error("生成报告出错：", error);
    message.error("生成报告出错，请稍后重试");
  }
};
/**
 * 显示模板选择对话框
 */
const showTemplateSelect = async () => {
  const appId = formSearchParams.value.appId || searchParams.value.appId;
  if (!appId) {
    message.warning("请先选择应用ID");
    return;
  }

  try {
    // 加载可用模板
    const res = await getAvailableTemplatesUsingGet({ appId: Number(appId) });
    if (res.data && res.data.code === 0) {
      templates.value = res.data.data || [];

      // 设置默认值
      reportForm.value = {
        reportName: `评分报告-${dayjs().format("YYYY-MM-DD")}`,
        reportDesc: `评分数据报告，生成于${dayjs().format(
          "YYYY-MM-DD HH:mm:ss"
        )}`,
        templateId: templates.value.length > 0 ? templates.value[0].id : null,
        generateAll: false,
        reportType: "standard",
      };

      templateSelectVisible.value = true;
    } else {
      message.error(
        "获取模板列表失败，" + (res.data ? res.data.message : "未知错误")
      );
    }
  } catch (error) {
    console.error("获取模板列表出错：", error);
    message.error("获取模板列表出错，请稍后重试");
  }
};

/**
 * 使用选定模板生成报告
 */
const generateReportWithTemplate = async () => {
  if (!reportForm.value.templateId) {
    message.warning("请选择报告模板");
    return;
  }

  const appId = formSearchParams.value.appId || searchParams.value.appId;
  if (!appId) {
    message.warning("请先选择应用ID");
    return;
  }

  generateLoading.value = true;
  try {
    const reportRequest = {
      appId: Number(appId),
      templateId: reportForm.value.templateId,
      reportName: reportForm.value.reportName,
      reportDesc: reportForm.value.reportDesc,
      resultName: formSearchParams.value.resultName,
      resultDesc: formSearchParams.value.resultDesc,
      userId: formSearchParams.value.userId,
      current: !reportForm.value.generateAll
        ? searchParams.value.current
        : undefined,
      pageSize: !reportForm.value.generateAll
        ? searchParams.value.pageSize
        : undefined,
      generateAll: reportForm.value.generateAll,
      reportType: reportForm.value.reportType,
    };

    const res = await generateReportUsingPost(reportRequest);
    if (res.data && res.data.code === 0) {
      message.success("报告生成成功");
      templateSelectVisible.value = false;

      // 保存报告ID
      if (res.data.data?.id) {
        const reportId = String(res.data.data.id);
        console.log("生成的报告ID:", reportId);
        generatedReportId.value = reportId;
        lastReportId.value = reportId; // 同时更新最新报告ID
        // 保存到本地存储
        localStorage.setItem("lastReportId", reportId);
      }
    } else {
      message.error(
        "报告生成失败，" + (res.data ? res.data.message : "未知错误")
      );
    }
  } catch (error) {
    console.error("生成报告出错：", error);
    message.error("生成报告出错，请稍后重试");
  } finally {
    generateLoading.value = false;
  }
};

// 加载可用模板
const loadTemplates = async () => {
  const appId = formSearchParams.value.appId || searchParams.value.appId;
  if (!appId) return;

  try {
    const res = await getAvailableTemplatesUsingGet({ appId: Number(appId) });
    if (res.data && res.data.code === 0) {
      templates.value = res.data.data || [];
    }
  } catch (error) {
    console.error("获取模板列表出错：", error);
  }
};

// 表格列配置
const columns = [
  {
    title: "id",
    dataIndex: "id",
  },
  {
    title: "名称",
    dataIndex: "resultName",
  },
  {
    title: "描述",
    dataIndex: "resultDesc",
  },
  {
    title: "图片",
    dataIndex: "resultPicture",
    slotName: "resultPicture",
  },
  {
    title: "结果属性",
    dataIndex: "resultProp",
  },
  {
    title: "评分范围",
    dataIndex: "resultScoreRange",
  },
  {
    title: "应用 id",
    dataIndex: "appId",
  },
  {
    title: "用户 id",
    dataIndex: "userId",
  },
  {
    title: "创建时间",
    dataIndex: "createTime",
    slotName: "createTime",
  },
  {
    title: "更新时间",
    dataIndex: "updateTime",
    slotName: "updateTime",
  },
  {
    title: "操作",
    slotName: "optional",
  },
];

// 初始化
onMounted(() => {
  // 从本地存储中恢复最后一个报告ID
  const storedReportId = localStorage.getItem("lastReportId");
  if (storedReportId) {
    lastReportId.value = storedReportId;
  }

  // 初始化加载数据
  loadData();
});

// 监听搜索参数变化，自动重新加载数据
watchEffect(() => {
  const { current, pageSize } = searchParams.value;
  if (current && pageSize) {
    loadData();
  }
});
</script>

<style scoped>
.report-link {
  color: #165dff;
  text-decoration: underline;
  cursor: pointer;
}

.report-link:hover {
  color: #0e42d2;
}

.report-success-message {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.view-report-btn {
  padding: 0 4px;
  color: #165dff;
}

.view-report-btn:hover {
  color: #0e42d2;
  text-decoration: underline;
}
</style>
