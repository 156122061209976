<template>
  <div ref="echartCalendar" style="width: 100%; height: 400px"></div>
</template>

<script setup lang="ts">
import { onMounted, ref } from "vue";
import * as echarts from "echarts";
import {
  addUserSignInUsingGet,
  getUserSignInRecordUsingGet,
} from "@/api/userController";
import { useLoginUserStore } from "@/store/userStore";
import message from "@arco-design/web-vue/es/message";
import dayjs from "dayjs";

// 获取登录用户ID
const loginUserStore = useLoginUserStore();
const userId = ref(loginUserStore.loginUser?.id);

// 获取签到记录并渲染 ECharts 日历
const renderEchartCalendar = async () => {
  const myChart = echarts.init(echartCalendar.value);

  // 获取真实的签到数据
  const getRealSignInData = async () => {
    try {
      const res = await getUserSignInRecordUsingGet({
        userId: userId.value,
        year: 202,
      });
      if (res.data.code === 0) {
        // 将签到的日期（天数）转换为 [yyyy-MM-dd, 1] 格式
        return res.data.data.map((dayOfYear: number) => {
          const date = dayjs(`${2025}-01-01`).add(dayOfYear - 1, "day"); // 生成对应的日期对象
          const formattedDate = date.format("YYYY-MM-DD"); // 格式化为 yyyy-MM-dd
          return [formattedDate, 1]; // 1 表示签到
        });
      } else {
        message.error(res.data.message);
        return [];
      }
    } catch (error) {
      message.error("加载签到记录失败");
      return [];
    }
  };

  // 调用 API 获取签到数据
  const data = await getRealSignInData();

  const option = {
    visualMap: {
      show: false,
      min: 0,
      max: 1,
      inRange: {
        // 颜色从灰色到浅绿色
        color: ["#efefef", "lightgreen"],
      },
    },
    calendar: {
      range: 2025, // 显示的年份范围
      left: 20,
      // 单元格自动宽度，高度为 16 像素
      cellSize: ["auto", 16],
      yearLabel: {
        position: "top",
        formatter: `${2025} 年答题记录`,
      },
    },
    tooltip: {
      // 配置鼠标悬停时显示的内容
      show: true,
      trigger: "item",
      formatter: (params) => {
        const date = params.value[0]; // 获取日期
        return `日期: ${date} <br/> 签到状态: ${
          params.value[1] === 1 ? "已签到" : "未签到"
        }`;
      },
    },
    series: {
      type: "heatmap",
      coordinateSystem: "calendar",
      data: data, // 这里的数据来自后端转换后的数据
    },
  };

  // 渲染图表
  myChart.setOption(option);

  // 响应式调整
  window.addEventListener("resize", () => {
    myChart.resize();
  });

  // 点击事件进行签到
  myChart.on("click", async (params) => {
    const clickedDate = params.value[0]; // 获取点击的日期
    await handleSignIn(clickedDate);
  });
};

// 点击日期进行签到
const handleSignIn = async (date: string) => {
  try {
    const res = await addUserSignInUsingGet({ date, userId: userId.value });
    if (res.data.code === 0) {
      message.success("签到成功");
      // 重新加载数据，更新日历显示
      renderEchartCalendar();
    } else {
      message.error(res.data.message);
    }
  } catch (error) {
    message.error("签到失败");
  }
};

// 自动签到功能
const autoSignIn = async () => {
  const today = dayjs().format("YYYY-MM-DD"); // 获取今天的日期
  await handleSignIn(today); // 使用今天的日期进行签到
};

// ECharts 容器引用
const echartCalendar = ref(null);

onMounted(() => {
  // 调用自动签到功能
  autoSignIn();
  renderEchartCalendar();
});
</script>

<style scoped>
/* 样式可以根据需要调整 */
</style>
